import React, { useLayoutEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import SEO from '../seo'
import Footer from './footer/Footer'
import Hero from './hero/Hero'
import HowItWorksContainer from './how-it-works/HowItWorks'
import Meals from './meals/Meals'
import RestaurantLogos from './restaurant-logos/RestaurantLogos'

type Props = RouteProps<'/catering'>

const CateringContainer: React.FC<Props> = () => {
  useLayoutEffect(() => {
    window.scrollTo(4, 4)
  }, [])

  return (
    <>
      <SEO title="Catering" />
      <Hero />
      <RestaurantLogos />
      <HowItWorksContainer />
      <Meals />
      <Footer />
    </>
  )
}

export default CateringContainer
