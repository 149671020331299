import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { compact, find, get, includes, isEmpty } from 'lodash'
import React, { useLayoutEffect, useState } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import UtmContent from '../../../models/UtmContent'
import { ApiService, UrlService } from '../../../services'
import InlineSpinner from '../../common/InlineSpinner'

const findValueFor = (property: string, json: MapboxGeocoder.Result): string =>
  get(
    find(json.context, (c) => includes(c.id, property)),
    'text',
    '',
  )

const HeroForm: React.FC<{ elementId: string; placeholder: string; action: string; position: string }> = ({
  elementId,
  action,
  placeholder,
  position,
}) => {
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [address, setAddress] = useState<MapboxGeocoder.Result>()
  const { showModal } = useHomepage()

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isLoading || isEmpty(address)) return

    setIsloading(true)
    const utmContent = new UtmContent({ position: `catering-${position}-form` }).toString()

    if (address == null || address == undefined) {
      return
    }

    try {
      const response = await ApiService.createCateringDeliveryAddress({
        full_address: address.place_name,
        country: findValueFor('country', address),
        region: findValueFor('region', address),
        locality: findValueFor('locality', address),
        place: findValueFor('place', address),
        postcode: findValueFor('postcode', address),
        neighborhood: findValueFor('neighborhood', address),
        address: compact([address.address, address.text]).join(' '),
        lat: address.center[1].toString(),
        long: address.center[0].toString(),
      })
      const deliveryId = response.data.id

      window.location.href = UrlService.cateringUrl(utmContent, deliveryId)
    } catch {
      setIsloading(false)
      showModal({ name: 'catering-delivery-form', settings: { utmContent, fullAddress: address.place_name } })
    }
  }

  const onResultHandler = ({ result }: { result: MapboxGeocoder.Result }): void => {
    setAddress(result)
  }

  useLayoutEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: process.env.MAPBOX_ACCESS_TOKEN || '',
      placeholder,
      limit: 5,
      proximity: {
        latitude: 40.740136,
        longitude: -73.992371,
      },
    })

    geocoder.addTo(`#${elementId}`)
    geocoder.on('result', onResultHandler)

    return () => {
      geocoder.off('result', onResultHandler)
      geocoder.onRemove()
    }
  }, [])

  return (
    <form className="form" onSubmit={onSubmitHandler}>
      <div className="mapbox-form__group">
        <div id={elementId} className="mapbox-element" />

        <button className="mapbox-form__action red-cta" type="submit">
          {isLoading ? <InlineSpinner /> : action}
        </button>
      </div>
    </form>
  )
}

export default HeroForm
