import React from 'react'

import Form from '../form/Form'
import './footer.less'

const Footer: React.FC = () => {
  return (
    <div className="catering-footer">
      <span className="catering-footer__title">Ready to eat?</span>
      <Form
        elementId="footer"
        position="footer"
        placeholder="Enter your delivery address"
        action="See what's near me"
      />
    </div>
  )
}

export default Footer
