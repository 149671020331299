import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import Icon3 from '../../../images/how-it-works-icon-7.svg'
import Icon2 from '../../../images/how-it-works-icon-8.svg'
import Icon1 from '../../../images/icons/reason-icon-save-money.svg'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './how-it-works.less'

const HowItWorksContainer = () => {
  const { t } = useTranslation()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'catering-how-it-works' }).toString()
    setUrl(UrlService.cateringUrl(utmContent))
  }, [])

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Start saving on catering today',
    })
  }

  return (
    <div className="catering-hiw">
      <div className="catering-hiw__body">
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
          <div className="catering-hiw__column">
            <div className="catering-hiw__icon">
              <img src={Icon1} />
            </div>
            <div className="catering-hiw__subtitle catering-hiw__subtitle--small">{t('Lower your costs')}</div>
            <div className="catering-hiw__description">
              {t('We are significantly less expensive than all other catering platforms.')}
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={200}>
          <div className="catering-hiw__column">
            <div className="catering-hiw__icon">
              <img src={Icon2} />
            </div>
            <div className="catering-hiw__subtitle">{t('Increase employee happiness')}</div>
            <div className="catering-hiw__description">
              {t('Hundreds of options, including Sweetgreen, Chipotle, and Shake Shack')}
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={400}>
          <div className="catering-hiw__column">
            <div className="catering-hiw__icon">
              <img src={Icon3} />
            </div>
            <div className="catering-hiw__subtitle">
              {t('Delivered when')}
              <br /> {t('you want it')}
            </div>
            <div className="catering-hiw__description">
              {t('Delivered to your office, with each meal individually packaged')}
            </div>
          </div>
        </ScrollAnimation>
      </div>
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
        <div className="catering-hiw__action">
          <a className="red-cta" href={url} onClick={onClickHandler}>
            {t('Start saving on catering today')}
          </a>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default HowItWorksContainer
