import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import ScrollAnimation from '../../scroll-animation/Container'
import './meals.less'

const Meals = () => {
  const { animationProps } = useHomepage()

  return (
    <div className="catering-meals">
      <div className="catering-meals__title">Hundreds of restaurants to choose from</div>
      <div className="catering-meals__wrapper">
        <ScrollAnimation {...animationProps} delay={300}>
          <div className="catering-meals__tile">
            <div>
              <div className="catering-meals__image">
                <img src="/images/catering-meals/beatnic.jpeg" />
              </div>
              <div className="catering-meals__logo">
                <img src="/images/catering-meals/beatnic-logo.jpeg" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} delay={300}>
          <div className="catering-meals__tile">
            <div>
              <div className="catering-meals__image">
                <img src="/images/catering-meals/five-guys.jpeg" />
              </div>
              <div className="catering-meals__logo">
                <img src="/images/catering-meals/five-guys-logo.png" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} delay={300}>
          <div className="catering-meals__tile">
            <div>
              <div className="catering-meals__image">
                <img src="/images/catering-meals/taim.jpeg" />
              </div>
              <div className="catering-meals__logo catering-meals__logo--small">
                <img src="/images/catering-meals/taim-logo.png" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} delay={300}>
          <div className="catering-meals__tile">
            <div>
              <div className="catering-meals__image">
                <img src="/images/catering-meals/pizza.jpg" />
              </div>
              <div className="catering-meals__logo catering-meals__logo--small">
                <img src="/images/catering-meals/pizza-logo.png" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  )
}

export default Meals
